<template>
  <b-button
    v-bind="$attrs"
    v-on="$listeners"
    :disabled="disabled || loading"
    class="k-btn"
  >
    <slot>
      <i
        v-if="!loading && icon"
        :class="[iconType, icon, { 'mr-1': label }]"
      ></i>
      <b-spinner
        small
        v-if="loading && icon"
        :class="{ 'mr-2': label }"
      ></b-spinner>
      {{ label }}
      <i
        v-if="!loading && rightIcon"
        :class="[iconType, rightIcon, { 'ml-1': label }]"
      ></i>
      <b-spinner
        small
        v-if="loading && rightIcon"
        :class="{ 'ml-2': label }"
      ></b-spinner>
    </slot>
  </b-button>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'button',
  data() {
    return {};
  },
  props: {
    label: {
      type: String,
      default: null,
    },
    icon: {
      type: String,
      default: null,
    },
    rightIcon: {
      type: String,
      default: null,
    },
    iconType: {
      type: String,
      default: 'fa',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
